import { Injectable } from '@angular/core';
import { Transition, TransitionService } from '@uirouter/core';

@Injectable({ providedIn: 'root' })
export class ScrollRestorationService {
    private scrollPositions = new Map<string, number>();

    constructor(private transitions: TransitionService) {
    }

    init() {
        // Before navigating away, store the scroll position
        this.transitions.onStart({}, (transition: Transition) => {
            const prevState = transition.from();
            if (this.shouldRestoreScroll(prevState)) {
                this.scrollPositions.set(prevState.name, window.scrollY);
            }
        });

        // After navigation, restore the scroll position if applicable
        this.transitions.onSuccess({}, (transition: Transition) => {
            const toState = transition.to();
            if (this.shouldRestoreScroll(toState)) {
                const scrollY = this.scrollPositions.get(toState.name) || 0;
                setTimeout(() => window.scrollTo(0, scrollY), 0);
            }
        });
    }

    private shouldRestoreScroll(state: any): boolean {
        return state?.data?.scrollPositionRestoration === true;
    }
}
