import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output, Renderer2 } from '@angular/core';

@Directive({
    selector: '[ftStickySpacer]'
})
export class StickySpacerDirective implements AfterViewInit, OnDestroy {
    @Input('ftStickySpacer') apply = true;
    @Input() offset = 15;
    @Input() stickyClass = '-sticky';
    @Input() scrollDirectionUpClass = '-direction-up';
    @Input() scrollDirectionDownClass = '-direction-down';
    @Input() scrollAtTopClass = '-scroll-at-top';
    @Output() scrollAtTop = new EventEmitter<boolean>();
    elementPosition: any;
    elementHeight: any;
    isStickyTop = false;
    spacerRef: HTMLElement;

    observer: ResizeObserver;

    listenerFunction: EventListenerOrEventListenerObject;
    scrollContainer: HTMLElement | Window = window;

    prevScrollTopPos = 0;


    constructor(private el: ElementRef,
                private renderer: Renderer2) {
    }

    ngAfterViewInit() {
        // Wait until rendering is finished in order to correctly calculate element position
        setTimeout(() => {
            this.initElement();
        });

        this.listenerFunction = this.onScroll.bind(this);
        window.addEventListener('scroll', this.listenerFunction, true);

        this.renderer.listen('window', 'resize', () => {
            this.unstickTop();
            this.initElement();
        });

        if (this.scrollContainer instanceof HTMLElement) {
            this.observer = new ResizeObserver(() => {
                this.reduceHostElementWidthByScrollBar();
            });
            this.observer.observe(this.scrollContainer);
        }
    }
    findScrollContainer(element: HTMLElement): HTMLElement | null {
        let parent = element.parentElement;
        while (parent) {
            const overflowY = window.getComputedStyle(parent).overflowY;
            // Detect actual scrollbar via scrollHeight/offsetHeight difference, since it could be that multiple parents have
            // style scroll auto/scroll, but actual scrollbar not on closest parent (fe. dialogs)
            if ((overflowY === 'auto' || overflowY === 'scroll') && parent.scrollHeight < parent.offsetHeight) {
                return parent;
            }
            parent = parent.parentElement;
        }
        return null;
    }

    getElementPosition(element: { offsetLeft: number; offsetTop: number; offsetParent: any; }) {
        let xPos = 0;
        let yPos = 0;
        while (element) {
            xPos += element.offsetLeft;
            yPos += element.offsetTop;
            element = element.offsetParent;
        }
        return { x: xPos, y: yPos };
    }

    onScroll(event: Event) {
        // skip cdk overlay scroll position rehydration, skip all elements which doesn't contain sticky element (textarea scrolling inside editor, for example)
        if (!event.bubbles && (event.target instanceof HTMLElement && event.target.contains(this.el.nativeElement)) || (!event.bubbles && (event.target instanceof Document || event.target instanceof Window))) {
            this.scrollContainer = event.target as HTMLElement;
        }
        const windowScroll = this.scrollContainer instanceof HTMLElement ? this.scrollContainer.scrollTop : window.pageYOffset;

        this.watchPosition();
        this.watchDirection(windowScroll);
    }

    ngOnDestroy() {
        this.observer?.disconnect();
        window.removeEventListener('scroll', this.listenerFunction, true);
        this.unstickTop();
    }

    initElement() {
        if (!this.apply) {
            return;
        }
        this.scrollContainer = this.findScrollContainer(this.el.nativeElement);
        this.prevScrollTopPos = this.scrollContainer instanceof HTMLElement ? this.scrollContainer.scrollTop : window.pageYOffset;
        this.elementPosition = this.getElementPosition(this.el.nativeElement).y;
        this.elementHeight = this.el.nativeElement.offsetHeight;
        this.watchPosition();
    }

    reduceHostElementWidthByScrollBar() {
        if (this.isStickyTop) {
            this.el.nativeElement.style.width = this.scrollContainer instanceof HTMLElement ? `calc(100% - ${this.scrollContainer?.offsetWidth - this.scrollContainer?.clientWidth}px)` : '100%';
        }
    }

    watchPosition() {
        if (!this.apply) {
            return;
        }
        if (!this.elementPosition) {
            this.elementPosition = this.getElementPosition(this.el.nativeElement)?.y;
        }
        const windowScroll = this.scrollContainer instanceof HTMLElement ? this.scrollContainer.scrollTop : window.pageYOffset;

        if (windowScroll > 0) {
            if (windowScroll >= this.elementPosition - this.offset) {
                if (!this.isStickyTop) {
                    this.stickTop();
                }
            } else {
                if (this.isStickyTop) {
                    this.unstickTop();
                }
            }
        }
    }

    watchDirection(windowScroll: number) {
        if (!this.apply) {
            return;
        }
        if (windowScroll > this.prevScrollTopPos) {
            this.el.nativeElement.classList.remove(this.scrollDirectionUpClass);
            this.el.nativeElement.classList.add(this.scrollDirectionDownClass);
        } else {
            this.el.nativeElement.classList.remove(this.scrollDirectionDownClass);
            this.el.nativeElement.classList.add(this.scrollDirectionUpClass);
        }
        this.prevScrollTopPos = windowScroll;
    }


    stickTop() {
        if (!this.apply) {
            return;
        }
        this.injectSpacerElement();

        this.setStickyStyles();

        if (this.scrollContainer instanceof HTMLElement) {
            this.reduceHostElementWidthByScrollBar()
        }
    }

    unstickTop() {
        if (!this.apply) {
            return;
        }

        this.removeSpacerElement();
        this.removeStickyStyles();
    }

    injectSpacerElement() {
        if (!this.spacerRef) {
            const elementHeight = this.el.nativeElement.offsetHeight;

            this.spacerRef = document.createElement('div');
            this.spacerRef.style.cssText = `height: ${elementHeight}px;`;
            this.el.nativeElement.parentElement?.insertBefore(this.spacerRef, this.el.nativeElement);
        }
    }

    removeSpacerElement() {
        if (this.spacerRef) {
            this.spacerRef.remove();
            this.spacerRef = null;
        }
    }

    setStickyStyles() {
        this.el.nativeElement.classList.add(this.stickyClass);
        this.renderer.setStyle(this.el.nativeElement, 'top', '0');
        this.renderer.setStyle(this.el.nativeElement, 'z-index', '999');
        this.el.nativeElement.style.width = this.scrollContainer instanceof HTMLElement ? `calc(100% - ${this.scrollContainer?.offsetWidth - this.scrollContainer?.clientWidth}px)` : '100%';

        this.renderer.setStyle(this.el.nativeElement, 'position', 'fixed');
        this.isStickyTop = true;
    }

    removeStickyStyles() {
        this.renderer.removeStyle(this.el.nativeElement, 'position');
        this.renderer.removeStyle(this.el.nativeElement, 'top');
        this.renderer.removeStyle(this.el.nativeElement, 'z-index');
        this.renderer.removeStyle(this.el.nativeElement, 'width');
        this.el.nativeElement.classList.remove(this.stickyClass);
        this.el.nativeElement.classList.remove(this.scrollDirectionUpClass);
        this.el.nativeElement.classList.remove(this.scrollDirectionDownClass);
        this.el.nativeElement.classList.remove(this.scrollAtTopClass);
        this.isStickyTop = false;
    }
}
